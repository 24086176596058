import { Grid } from "@mui/material";
import { canReadDashboard } from "../../store/actions/LoginActions";
import MenuLayout from "../app/MenuLayout";
import { COLOR_GRAY_2 } from "../atoms/ImsMaterialTheme";
import BonusPanel from "./BonusPanel";
import CustomerActivityPanel from "./CustomerActivityPanel";
import CustomerRegistrationPanel from "./CustomerRegistrationPanel";
import CustomerStatusPanel from "./CustomerStatusPanel";

export const MainDashboardPage = () => (
  <MenuLayout headline="Dashboard">
    <Grid container>
      <Grid item md={12}>
        {canReadDashboard() && (
          <>
            <CustomerActivityPanel />
            <BonusPanel />
            <CustomerRegistrationPanel />
            <CustomerStatusPanel />
          </>
        )}
      </Grid>
    </Grid>
  </MenuLayout>
);

export const chartColors = () => (
  <defs>
    <linearGradient id="colorGray" x1="0" y1="0" x2="0" y2="100%" spreadMethod="reflect">
      <stop stopColor={COLOR_GRAY_2} />
      <stop offset="1" stopColor="rgba(102, 102, 102, 0.5)" />
    </linearGradient>
  </defs>
);

export const formatAxisDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${date.getDate()}.${date.getMonth() + 1}.\n${
    ["MO", "DI", "MI", "DO", "FR", "SA", "SO"][(date.getDay() + 6) % 7]
  }`;
};
