import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IStore } from "../../store/IStore";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { logout, thunkPublicUserLogin } from "../../store/actions/LoginActions";
import { thunkCreateErrorNotification } from "../../store/actions/NotificationActions";
import { connect, ConnectedProps } from "react-redux";
import { Box, Button, CssBaseline, Grid, Paper, Theme, Typography, useTheme } from "@mui/material";
import {
  thunkDownloadPkPass,
  thunkDownloadPkPassForCustomerIdentificationNumber,
} from "../../store/actions/PkPassDownloadAction";
import { KNISTR_BLUE } from "../atoms/ImsMaterialTheme";
import harborNewYearBackground from "../atoms/images/harbor-new-year-background.png";
import NotificationBar from "../notification/NotificationBar";

const PkPassDownloadPage = (props: ThunkProps) => {
  const theme = useTheme();
  const classes = styles(theme);
  const location = useLocation();
  const [countdown, setCountdown] = useState(5);
  const passNumber = new URLSearchParams(location.search).get("passNumber");
  const customerIdentificationNumber = new URLSearchParams(location.search).get("customerIdentificationNumber");
  const token = new URLSearchParams(location.search).get("token");

  const getAccessToken = useCallback(async () => {
    const accessToken = await props.thunkPublicUserLogin();
    if (!accessToken) {
      throw new Error("Access token is missing");
    }
    return accessToken.toString();
  }, [props]);

  const triggerDownload = useCallback(async () => {
    const accessToken = await getAccessToken();
    if (passNumber && token) {
      props.thunkDownloadPkPass(passNumber, encodeURIComponent(token), accessToken);
    } else {
      if (customerIdentificationNumber && token) {
        props.thunkDownloadPkPassForCustomer(customerIdentificationNumber, encodeURIComponent(token), accessToken);
      } else {
        throw new Error("Pass number or token is missing");
      }
    }
  }, [props, getAccessToken, passNumber, customerIdentificationNumber, token]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (countdown > 0) {
      timer = setInterval(() => setCountdown(countdown - 1), 1000);
    } else {
      triggerDownload();
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [triggerDownload, props, location.pathname, location.search, countdown]);

  return (
    <>
      <NotificationBar />
      <CssBaseline />

      <Paper sx={classes.login}>
        <Grid container spacing={3} style={{ padding: theme.spacing(2) }}>
          <Grid item xs={12} style={{ fontWeight: "bold", textAlign: "center" }}>
            <Typography variant="h5">Ihr Pass wird in Kürze heruntergeladen</Typography>
            <Typography variant="body1">
              Sollte der Vorgang nicht automatisch in den nächsten {countdown} Sekunden starten, klicken Sie bitte auf
            </Typography>
            <Box marginTop={2}>
              <Button variant="contained" color="primary" onClick={triggerDownload}>
                Jetzt herunterladen
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Box component="div" sx={classes.blueBackground}>
        <Box component="div" sx={classes.pictureBackground}></Box>
      </Box>
    </>
  );
};

const styles = (theme: Theme) => ({
  blueBackground: {
    backgroundColor: KNISTR_BLUE,
  },
  pictureBackground: {
    width: "100%",
    minHeight: "100vh",
    maxWidth: "100%",
    backgroundImage: `url(${harborNewYearBackground})`,
    backgroundSize: "cover",
    opacity: 0.35,
  },
  login: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "476px",
    marginTop: "-200px",
    marginLeft: "-238px",
    zIndex: 1001,
    padding: theme.spacing(3),
  },
});
const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkPublicUserLogin,
      logout,
      thunkDownloadPkPass,
      thunkDownloadPkPassForCustomer: thunkDownloadPkPassForCustomerIdentificationNumber,
      thunkCreateErrorNotification,
    },
    dispatch
  );
const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(PkPassDownloadPage);
