import React from "react";
import { Grid, Tooltip } from "@mui/material";
import { Help } from "@mui/icons-material";
import FormColor from "../../atoms/FormColor";

interface ColorFormWithTooltipProps {
  name: string;
  label: string;
  tooltipText: string;
  dataTestId: string;
}

const ColorFormWithTooltip: React.FC<ColorFormWithTooltipProps> = ({ name, label, tooltipText, dataTestId }) => (
  <Grid item container spacing={2}>
    <Grid item sm={2}>
      <FormColor name={name} label={label} />
    </Grid>
    <Grid item sm={1}>
      <Tooltip data-testid={dataTestId} title={tooltipText} placement="right-start" style={{ marginTop: 16 }}>
        <Help color="primary" />
      </Tooltip>
    </Grid>
  </Grid>
);

export default ColorFormWithTooltip;
