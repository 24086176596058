import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import MenuLayout from "../../app/MenuLayout";
import CarouselDetail from "./AppContentCarouselDetail";

const AppContentCarouselFormPage = (props: ThunkProps) => {
  const { id } = useParams<"id">();

  return (
    <MenuLayout headline={id ? "Carousel bearbeiten" : "Neues Carousel"}>
      <CarouselDetail />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;

export default connector(AppContentCarouselFormPage);
