import { Button, Grid, Paper, Tooltip, useTheme } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathPassMedium } from "../../../api/url";
import { IStore } from "../../../store/IStore";
import { PassMediumDto, PassMediumType, passMediumTypes } from "../../../store/models/passmedium/PassMediumDto";
import { FormInput } from "../../atoms/FormInput";
import ImsPaperHead from "../../atoms/ImsPaperHead";
import { isBlank, nameof, useMountEffect, ValidationErrors } from "../../atoms/Utils";
import { FormSelect } from "../../atoms/FormSelect";
import {
  thunkCreatePassMedium,
  thunkGetPassMediumByNumber,
  thunkUpdatePassMedium,
} from "../../../store/actions/PassMediumAction";
import { FormCheckbox } from "../../atoms/FormCheckbox";
import { FormRadioButton } from "../../atoms/FormRadioButton";
import { passIdViewOptions } from "./PassIdView";
import { Help } from "@mui/icons-material";
import { thunkCreateErrorNotification } from "../../../store/actions/NotificationActions";
import imagePlaceholder from "../../appContent/imagePlaceholder.png";
import { useParams } from "react-router-dom";
import { Api } from "../../../api/Api";
import { BackdropProcessing } from "../../app/BackdropProcessing";
import ImageUpload from "./ImageUpload";
import ColorFormWithTooltip from "./ColorFormWithTooltipProps";

interface PassMediumFormProps {
  passMedium: PassMediumDto;
  isEditMode: boolean;
}

const loadImageFromUrl = async (fileName: string | undefined, url: string | undefined): Promise<File> => {
  if (!fileName || !url) {
    return new File([], "");
  }
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], fileName, { type: blob.type });
};

const PassMediumForm = (props: PassMediumFormProps & ThunkProps) => {
  const { isEditMode } = props;
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);
  const [passMedium, setPassMedium] = useState<PassMediumDto>();
  const [processing, setProcessing] = useState<boolean>(true);

  const [stripHochladenImageFile, setStripHochladenImageFile] = useState<File>();
  const [stripHochladenImagePreview, setStripHochladenImagePreview] = useState<string>();

  const [logoHochladenImageFile, setLogoHochladenImageFile] = useState<File>();
  const [logoHochladenImagePreview, setLogoHochladenImagePreview] = useState<string>();

  const [iconHochladenImageFile, setIconHochladenImageFile] = useState<File>();
  const [iconHochladenImagePreview, setIconHochladenImagePreview] = useState<string>();

  const { id: passMediumNumber } = useParams<"id">();
  const getPassMedium = async (passMediumNumber: string) => {
    return await Api.getPassMediumByNumber(passMediumNumber);
  };

  useMountEffect(() => {
    const fetchPassMedium = async () => {
      if (!passMediumNumber) {
        setStripHochladenImagePreview(imagePlaceholder);
        setLogoHochladenImagePreview(imagePlaceholder);
        setIconHochladenImagePreview(imagePlaceholder);
        setProcessing(false);
        return;
      }

      try {
        const passMedium = await getPassMedium(passMediumNumber);
        setPassMedium(passMedium);

        await Promise.all([
          loadImageAndReadData(
            passMedium.imageNameStrip,
            passMedium.imageLinkStrip,
            readImageStripData,
            setStripHochladenImagePreview
          ),
          loadImageAndReadData(
            passMedium.imageNameLogo,
            passMedium.imageLinkLogo,
            readImageLogoData,
            setLogoHochladenImagePreview
          ),
          loadImageAndReadData(
            passMedium.imageNameIcon,
            passMedium.imageLinkIcon,
            readImageIconData,
            setIconHochladenImagePreview
          ),
        ]);

        setProcessing(false);
      } catch (error) {
        props.thunkCreateErrorNotification("Error", "Fehler bei der Bilddarstellung");
        setProcessing(false);
        setError(true);
      }
    };

    fetchPassMedium();
  });

  const loadImageAndReadData = async (
    imageName: string | null | undefined,
    imageLink: string | null | undefined,
    readImageData: (file: File) => void,
    setImagePreview: (preview: string) => void
  ) => {
    if (!imageName || !imageLink) {
      setImagePreview(imagePlaceholder);
      return;
    }

    try {
      const file = await loadImageFromUrl(imageName, imageLink);
      readImageData(file);
    } catch (error) {
      console.error(`Error loading image ${imageName}:`, error);
    }
  };

  const readImageData =
    (setImageFile: (file: File) => void, setImagePreview: (preview: string) => void) => (file: File) => {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    };

  const readImageStripData = readImageData(setStripHochladenImageFile, setStripHochladenImagePreview);
  const readImageLogoData = readImageData(setLogoHochladenImageFile, setLogoHochladenImagePreview);
  const readImageIconData = readImageData(setIconHochladenImageFile, setIconHochladenImagePreview);

  const savePassMedium = async (passMediumToSave: PassMediumDto) => {
    let success;
    if (passMediumToSave.passMediumNumber) {
      if (passMediumToSave.type === PassMediumType.PKPASS) {
        success = await props.thunkUpdatePassMedium(
          passMediumToSave,
          stripHochladenImageFile,
          logoHochladenImageFile,
          iconHochladenImageFile,
          passMediumToSave.passMediumNumber
        );
      } else {
        success = await props.thunkUpdatePassMedium(
          passMediumToSave,
          undefined,
          undefined,
          undefined,
          passMediumToSave.passMediumNumber
        );
      }
    } else {
      if (passMediumToSave.type === PassMediumType.PKPASS) {
        success = await props.thunkCreatePassMedium(
          passMediumToSave,
          stripHochladenImageFile,
          logoHochladenImageFile,
          iconHochladenImageFile
        );
      } else {
        success = await props.thunkCreatePassMedium(passMediumToSave, undefined, undefined, undefined);
      }
    }
    if (success) {
      setRedirect(true);
      setProcessing(false);
    }
  };

  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setImageFile: (file: File) => void,
    setImagePreview: (preview: string) => void
  ) => {
    if (event.target.files?.[0]) {
      const file = event.target.files[0];
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBildChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    handleImageChange(event, setStripHochladenImageFile, setStripHochladenImagePreview);
  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    handleImageChange(event, setLogoHochladenImageFile, setLogoHochladenImagePreview);
  const handleIconChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    handleImageChange(event, setIconHochladenImageFile, setIconHochladenImagePreview);

  if (redirect || error) {
    return <Navigate to={UrlPathPassMedium} />;
  }

  const validateForm = (values: PassMediumDto) => {
    const errors: ValidationErrors<PassMediumDto> = {};
    if (isBlank(values.motiv)) {
      errors.motiv = "Bitte geben Sie ein Motiv ein.";
    }
    return errors;
  };

  return (
    <Form
      onSubmit={savePassMedium}
      initialValues={passMedium}
      validate={validateForm}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Paper style={{ padding: theme.spacing(3), marginTop: theme.spacing(6) }}>
            <BackdropProcessing processing={processing}></BackdropProcessing>
            <ImsPaperHead text="Wie lauten die Details des Pass Mediums?" />
            <Grid container spacing={3}>
              <Grid item container spacing={2}>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth
                    type="text"
                    name={nameof<PassMediumDto>("motiv")}
                    label="Motiv"
                    readOnly={isEditMode}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item sm={4}>
                  <FormSelect
                    name={nameof<PassMediumDto>("type")}
                    label={"Format"}
                    options={passMediumTypes}
                    fullWidth
                    readOnly={isEditMode}
                  />
                </Grid>
              </Grid>
              {values.type === PassMediumType.PKPASS && (
                <>
                  <Grid item sm={6}>
                    <b>PKPASS Content Details</b>
                  </Grid>
                  <Grid item container spacing={2}>
                    <Grid item sm={4}>
                      <FormInput
                        variant="outlined"
                        fullWidth
                        type="text"
                        name={nameof<PassMediumDto>("programName")}
                        label="Programmname"
                      />
                    </Grid>
                    <Grid item sm={1}>
                      <Tooltip
                        data-testid="programmNameTooltip"
                        title={"Dieser Text wird unter dem Streifenbild dargestellt."}
                        placement="right-start"
                        style={{ marginTop: 16 }}
                      >
                        <Help color="primary" />
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item sm={4}>
                      <FormInput
                        variant="outlined"
                        fullWidth
                        type="text"
                        name={nameof<PassMediumDto>("logoText")}
                        label="Logo Text"
                      />
                    </Grid>
                    <Grid item sm={1}>
                      <Tooltip
                        data-testid="logoTextTooltip"
                        title={"Dieser Text wird rechts vom Pass-Logo dargestellt."}
                        placement="right-start"
                        style={{ marginTop: 16 }}
                      >
                        <Help color="primary" />
                      </Tooltip>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item sm={3}>
                        <FormCheckbox
                          name={nameof<PassMediumDto>("includeName")}
                          label="Name des Loyalty Programm-Mitglieds"
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item sm={3}>
                        <FormCheckbox
                          name={nameof<PassMediumDto>("includeExternalConnectionId")}
                          label="Kunden- oder Prepaid Account-Nummer"
                        />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item sm={3}>
                        <FormCheckbox name={nameof<PassMediumDto>("includePassId")} label="Pass ID" />
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item sm={4}>
                        <FormRadioButton
                          label="Pass ID Darstellung"
                          name={nameof<PassMediumDto>("barcodeFormat")}
                          options={passIdViewOptions}
                          value={values.barcodeFormat}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={6}>
                    <b>PKPASS Layout Details</b>
                  </Grid>
                  <ColorFormWithTooltip
                    name={nameof<PassMediumDto>("backgroundColor")}
                    label="Hintergrundfarbe"
                    tooltipText="Diese Farbe wird als Hintergrundfarbe des Passes verwendet."
                    dataTestId="backgroundColorTooltip"
                  />

                  <ColorFormWithTooltip
                    name={nameof<PassMediumDto>("foregroundColor")}
                    label="Schriftfarbe"
                    tooltipText="Diese Farbe wird als Schriftfarbe für verschiedene Daten auf dem Pass verwendet."
                    dataTestId="fontColorTooltip"
                  />

                  <ColorFormWithTooltip
                    name={nameof<PassMediumDto>("labelColor")}
                    label="Labelfarbe"
                    tooltipText="In dieser Farbe werden verschiedene Labels auf dem Pass dargestellt."
                    dataTestId="labelColorTooltip"
                  />
                  <ImageUpload
                    imagePreview={stripHochladenImagePreview}
                    altText="Hintergrundbild"
                    tooltipText="Dieses Bild dient als Hintergrund für den gesamten Pass."
                    buttonText="Bild hochladen"
                    handleChange={handleBildChange}
                    processing={processing}
                  />

                  <ImageUpload
                    imagePreview={logoHochladenImagePreview}
                    altText="Logo"
                    tooltipText="Dieses Logo wird in der oberen rechten Ecke des Passes dargestellt."
                    buttonText="LOGO HOCHLADEN"
                    handleChange={handleLogoChange}
                    processing={processing}
                  />

                  <ImageUpload
                    imagePreview={iconHochladenImagePreview}
                    altText="Icon"
                    tooltipText="Das Icon wird angezeigt, wenn ein Pass auf dem Sperrbildschirm erscheint."
                    buttonText="ICON HOCHLADEN"
                    handleChange={handleIconChange}
                    processing={processing}
                  />
                </>
              )}
            </Grid>
          </Paper>
          <Grid container justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setRedirect(true)}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              Abbrechen
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={submitting}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              Speichern
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};

const mapStateToProps = (state: IStore) => ({
  passMediums: state.passMediums.passMediums,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkUpdatePassMedium,
      thunkCreatePassMedium,
      thunkCreateErrorNotification,
      thunkGetPassMediumByNumber,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(PassMediumForm);
