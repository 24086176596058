import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, Paper, Tooltip, useTheme } from "@mui/material";
import { Navigate, useParams } from "react-router-dom";
import { UrlPathAppContentCarousel } from "../../../api/url";
import { IStore } from "../../../store/IStore";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Form } from "react-final-form";
import { FormInput } from "../../atoms/FormInput";
import { nameof } from "../../atoms/Utils";
import ImsPaperHead from "../../atoms/ImsPaperHead";
import {
  thunkCreateCarousel,
  thunkGetCarouselByNumber,
  thunkUpdateCarousel,
} from "../../../store/actions/CarouselActions";
import { thunkCreateErrorNotification } from "../../../store/actions/NotificationActions";
import { CarouselDto } from "../../../store/models/appcontent/CarouselDto";
import { CarouselInboundDto } from "../../../store/models/appcontent/CarouselInboundDto";
import { Api } from "../../../api/Api";
import { Help, Publish } from "@mui/icons-material";
import imagePlaceholder from "../imagePlaceholder.png";
import { BackdropProcessing } from "../../app/BackdropProcessing";

const loadImageFromUrl = async (fileName: string, url: string): Promise<File> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], fileName, { type: blob.type });
};

const AppContentCarouselDetail = (props: ThunkProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const [imageFile, setImageFile] = useState<File>();
  const [imagePreview, setImagePreview] = useState<string>();
  const [processing, setProcessing] = useState<boolean>(true);
  const [isImageDownloading, setIsImageDownloading] = useState<boolean>(true);
  const [carousel, setCarousel] = useState<CarouselDto>();

  const { id: carouselNumber } = useParams<"id">();
  const getCarousel = async (carouselNumber: string) => {
    return await Api.getCarouselByNumber(carouselNumber);
  };

  useEffect(() => {
    if (!carouselNumber) {
      setImagePreview(imagePlaceholder);
      setProcessing(false);
      return;
    }

    getCarousel(carouselNumber)
      .then((carousel) => {
        setCarousel(carousel);
        setImagePreview(carousel.imageLink);
        setProcessing(false);
        loadImageFromUrl(carousel.imageName, carousel.imageLink).then((file) => {
          readImageData(file);
        });

        return carousel;
      })
      .catch(() => {
        props.thunkCreateErrorNotification("Error", "Fehler bei der Bilddarstellung");
      });
  }, [carouselNumber, props]);

  const readImageData = (file: File) => {
    setImageFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const saveCarousel = async (carouselToSave: CarouselDto) => {
    if (!imageFile) {
      props.thunkCreateErrorNotification("Error", "Keine Bilddatei für neues Carousel hochgeladen!");
      return;
    }
    setProcessing(true);
    let success;
    let carouselInfo: CarouselInboundDto = {
      url: carouselToSave.url,
      title: carouselToSave.title,
      subtitle: carouselToSave.subtitle,
    };
    if (carouselToSave.carouselNumber) {
      success = await props.thunkUpdateCarousel(carouselInfo, imageFile, carouselToSave.carouselNumber);
    } else {
      success = await props.thunkCreateCarousel(carouselInfo, imageFile);
    }
    if (success) {
      setRedirect(true);
    }
    setProcessing(false);
  };
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  if (redirect) {
    return <Navigate to={UrlPathAppContentCarousel} />;
  }
  const allFieldsFilled = (values: any) => {
    return values["title"] && imageFile;
  };
  return (
    <Form
      onSubmit={saveCarousel}
      initialValues={carousel}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Paper style={{ padding: theme.spacing(3), marginTop: theme.spacing(6) }}>
            <BackdropProcessing processing={processing}></BackdropProcessing>
            <ImsPaperHead text="Wie lauten die Carousel Details?" />
            <Grid container spacing={4}>
              <Grid item md={5}>
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <b>Carousel Daten</b>
                  </Grid>
                  <Grid item sm={11}>
                    <FormInput
                      variant="outlined"
                      fullWidth={true}
                      type="text"
                      name={nameof<CarouselDto>("title")}
                      label="Titel"
                    />
                  </Grid>
                  <Grid item sm={11}>
                    <FormInput
                      variant="outlined"
                      fullWidth={true}
                      type="text"
                      name={nameof<CarouselDto>("subtitle")}
                      label="Untertitel"
                    />
                  </Grid>
                  <Grid item sm={11}>
                    <FormInput
                      variant="outlined"
                      fullWidth={true}
                      type="text"
                      name={nameof<CarouselDto>("url")}
                      label="URL"
                    />
                  </Grid>
                  <Grid item sm={1}>
                    <Tooltip
                      data-testid="urlTooltip"
                      title={`Beispiel-URL zum Verlinken von internen Ressourcen: internal://wheeloffortune`}
                      placement="right-start"
                      style={{ marginTop: 16 }}
                    >
                      <Help color="primary" />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                md={7}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box mt={2} position={"relative"} minHeight={"20vh"} mr={2}>
                  <img
                    src={imagePreview}
                    alt="Carousel"
                    onLoad={() => setIsImageDownloading(false)}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "500px",
                      width: "auto",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                  {isImageDownloading && (
                    <Box
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "10vh",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>

                <Box mt={2}>
                  <Button variant="contained" component="label" color="primary" startIcon={<Publish />}>
                    Bild hochladen
                    <input type="file" accept="image/*" hidden onChange={handleImageChange} />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
          <Grid container justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setRedirect(true)}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              Abbrechen
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
              disabled={!allFieldsFilled(values)}
            >
              Speichern
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};
const mapStateToProps = (_: IStore) => ({});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkUpdateCarousel,
      thunkCreateCarousel,
      thunkGetCarouselByNumber,
      thunkCreateErrorNotification,
    },
    dispatch
  );
const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(AppContentCarouselDetail);
