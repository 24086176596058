import { Api } from "../../api/Api";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";
import { BooleanThunk } from "../IStore";

export const thunkDownloadPkPass =
  (passNumber: string, token: string, accessToken: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const res = await Api.downloadPkPass(passNumber, token, accessToken);
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "pkpass.pkpass";
        document.body.appendChild(a);
        a.click();
        a.remove();
        dispatch(thunkCreateSuccessNotification("PKPass downloaded successfully!"));
        return true;
      }
      return false;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Can not download PKPass", e));
      return false;
    }
  };

export const thunkDownloadPkPassForCustomerIdentificationNumber =
  (customerNumber: string, token: string, accessToken: string): BooleanThunk =>
  async (dispatch) => {
    try {
      const res = await Api.downloadPassForCustomerIdentificationNumber(customerNumber, token, accessToken);
      if (res.ok) {
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "pkpass.pkpass";
        document.body.appendChild(a);
        a.click();
        a.remove();
        dispatch(thunkCreateSuccessNotification("PKPass downloaded successfully!"));
        return true;
      }
      return false;
    } catch (e) {
      dispatch(thunkCreateErrorNotification("Can not download PKPass", e));
      return false;
    }
  };
