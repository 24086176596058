import { Field } from "react-final-form";
import { ChromePicker, ColorResult } from "react-color";
import { FormControl, FormHelperText, FormLabel } from "@mui/material";

interface FormColorProps {
  name: string;
  label: string;
  fullWidth?: boolean;
}

const FormColor = ({ name, label, fullWidth }: FormColorProps) => {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <FormControl fullWidth={fullWidth} error={meta.touched && meta.error}>
          <FormLabel>{label}</FormLabel>
          <ChromePicker color={input.value} onChange={(color: ColorResult) => input.onChange(color.hex)} />
          {meta.touched && meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

export default FormColor;
