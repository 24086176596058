import React from "react";
import { Box, Button, CircularProgress, Tooltip } from "@mui/material";
import { Help } from "@mui/icons-material";
import { Grid } from "@mui/material";

interface ImageUploadProps {
  imagePreview: string | undefined;
  altText: string;
  tooltipText: string;
  buttonText: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  processing: boolean;
}

const ImageUpload = ({
  imagePreview,
  altText,
  tooltipText,
  buttonText,
  handleChange,
  processing,
}: ImageUploadProps) => (
  <Grid item container spacing={2}>
    <Grid item sm={1}>
      <b>{altText}</b>
    </Grid>
    <Grid item sm={1}>
      <Tooltip title={tooltipText} placement="right-start" style={{ marginTop: 2 }}>
        <Help color="primary" />
      </Tooltip>
    </Grid>
    <Grid item container spacing={2}>
      <Box mt={2} position={"relative"} minHeight={"20vh"} mr={2}>
        <img
          src={imagePreview}
          alt={altText}
          style={{
            maxWidth: "100%",
            maxHeight: "500px",
            width: "auto",
            height: "auto",
            borderRadius: "8px",
          }}
        />
        {processing && (
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "10vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Grid>
    <Button variant="contained" component="label" color="primary">
      <b>{buttonText}</b>
      <input type="file" accept="image/*" hidden onChange={handleChange} />
    </Button>
  </Grid>
);

export default ImageUpload;
