import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { UrlPathBonusCreate } from "../../api/url";
import { canMaintainBonus } from "../../store/actions/LoginActions";
import MenuLayout from "../app/MenuLayout";
import BonusTable from "./BonusRuleTable";
import BonusSearch from "./BonusSearch";
import { useState } from "react";

export interface BonusSearchCondition {
  name?: string;
  isRunning?: boolean;
  showInactive?: boolean;
  unitNumbers?: string[];
}

export const BonusRulePage = () => {
  const [bonusSearchCondition, setBonusSearchCondition] = useState<BonusSearchCondition>({});
  return (
    <MenuLayout headline="Bonusregeln">
      <Grid container spacing={2}>
        {canMaintainBonus() && (
          <Grid container item justifyContent="flex-end">
            <Button component={Link} to={UrlPathBonusCreate} startIcon="+" variant="contained" color="primary">
              Neue Bonusregel
            </Button>
          </Grid>
        )}
        <Grid item md={12}>
          <BonusSearch setSearchCondition={setBonusSearchCondition} />
        </Grid>
        <Grid item md={12}>
          <BonusTable searchCondition={bonusSearchCondition} />
        </Grid>
      </Grid>
    </MenuLayout>
  );
};
