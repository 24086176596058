export interface AppLinkDto {
  appLinkNumber: string;
  url: string;
  text: string;
  position: string;
  order: number;
}

export enum AppLinkPosition {
  FOOTER = "FOOTER",
  LINKLIST = "LINKLIST",
}

export const appLinkPositionOptions = [
  {
    label: "Footer",
    value: AppLinkPosition.FOOTER,
  },
  {
    label: "LinkList",
    value: AppLinkPosition.LINKLIST,
  },
];
