import { PassIdView } from "../../../components/setup/passmedium/PassIdView";

export interface PassMediumDto {
  passMediumNumber: string;
  motiv: string;
  type: PassMediumType;
  programName?: string;
  logoText?: string;
  backgroundColor?: string;
  foregroundColor?: string;
  labelColor?: string;
  includeName?: boolean;
  includeExternalConnectionId?: boolean;
  includePassId?: boolean;
  barcodeFormat?: PassIdView;
  imageNameStrip?: string;
  imageLinkStrip?: string;
  imageNameLogo?: string;
  imageLinkLogo?: string;
  imageNameIcon?: string;
  imageLinkIcon?: string;
}

export enum PassMediumType {
  PKPASS = "PKPASS",
  PLASTIC = "PLASTIC",
}

export function getLabel(type: string): string {
  switch (type) {
    case "PKPASS":
      return "Digital PKPASS";
    case "PLASTIC":
      return "Plastikkarte";
    default:
      return type;
  }
}

export const passMediumTypes = [
  {
    label: "Digital PKPASS",
    value: PassMediumType.PKPASS,
  },
  {
    label: "Plastikkarte",
    value: PassMediumType.PLASTIC,
  },
];
