import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { OptionHTMLAttributes, useEffect, useState } from "react";
import { Field } from "react-final-form";
import { FieldConfigDto } from "../../store/models/fieldConfig/FieldConfigDto";
import { isFieldNeeded } from "./FieldValidation";
import { COLOR_WHITE } from "./ImsMaterialTheme";

interface FormSelectProps {
  name: string;
  label: string;
  options: OptionHTMLAttributes<HTMLOptionElement>[];
  fullWidth?: boolean;
  testId?: string;
  readOnly?: boolean;
  multiple?: boolean;
  fieldConfig?: FieldConfigDto[];
  hierarchyLogic?: boolean;
  initialValues?: string[];
  helperText?: string;
}

export const FormSelect = (props: FormSelectProps) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  useEffect(() => {
    if (
      !props.readOnly &&
      props.hierarchyLogic &&
      props.initialValues !== undefined &&
      props.initialValues &&
      props.initialValues.length > 0
    ) {
      const valueSelected = props.initialValues[0];
      const initIndex = props.options.filter((option) => option.value === valueSelected)[0]?.tabIndex;
      if (initIndex !== undefined && initIndex >= 0) {
        setSelectedIndex(initIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValues]);

  if (props.fieldConfig && !isFieldNeeded(props.name, props.fieldConfig)) return null;
  const handleSelectedValue = (currentSelectedValues: string[], selectedValue: any, tabIndex: number | undefined) => {
    if (!props.hierarchyLogic || tabIndex === undefined) {
      return;
    }
    if (currentSelectedValues.includes(selectedValue) && currentSelectedValues.length === 1) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(tabIndex);
    }
  };

  return (
    <Field name={props.name} type={props.multiple ? "field" : "select"}>
      {({ input, meta }) => {
        const hasErrors = Boolean(meta.error && meta.touched);
        const helperText = props.helperText || meta.error;
        return (
          <FormControl
            data-testid={props.testId || "formselect-" + props.name}
            variant="outlined"
            fullWidth={props.fullWidth}
            error={hasErrors}
          >
            <InputLabel id={"lbl-select-" + props.name} style={{ backgroundColor: COLOR_WHITE }}>
              {props.label}
            </InputLabel>
            <Select
              {...input}
              multiple={props.multiple}
              disabled={props.readOnly}
              id={"select-" + props.name}
              inputProps={{ "aria-labelledby": "lbl-select-" + props.name }}
              variant="outlined"
              style={{ minWidth: 200 }}
              error={hasErrors}
            >
              {props.options.map(({ label, value, style, tabIndex }) => (
                <MenuItem
                  key={"selectKey" + value}
                  value={value}
                  style={style}
                  disabled={
                    (props.hierarchyLogic && selectedIndex >= 0 && tabIndex !== selectedIndex) ||
                    (tabIndex === undefined && selectedIndex >= 0)
                  }
                  onClick={() => {
                    handleSelectedValue(input.value, value, tabIndex);
                  }}
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
            {(hasErrors || props.helperText) && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
};
