import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { UserDto, UserRole } from "../../store/models/user/UserDto";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import UserTableMenu from "./UserTableMenu";

export interface UserRoleName {
  key: UserRole;
  value: string;
}

export const userRoleNames: UserRoleName[] = [
  { key: UserRole.SYSTEM_ADMINISTRATOR, value: "Systemadministration" },
  { key: UserRole.USER_ADMINISTRATOR, value: "Anwenderadminstration" },
  { key: UserRole.PROGRAM_ADMINISTRATOR, value: "Programmadministration" },
  { key: UserRole.PROGRAM_ANALYST, value: "Programmanalyse" },
  { key: UserRole.CUSTOMER_SERVICE_AGENT, value: "Konsumentenservice" },
  { key: UserRole.SALES_PERSON, value: "Verkaufsmitarbeiter" },
];

const UserTable = (props: ThunkProps) => {
  const { users, operationalUnits } = props;
  const headCells: ImsHeadCell<UserDto>[] = [
    { val: (val) => val.userName, node: "Anwendername" },
    { val: (val) => val.mailAddress, node: "E-Mail" },
    { val: (val) => val.unitNumber, node: "Filiale" },
    { val: (val) => val.userRoles, node: "Rollen" },
  ];

  const rows: ImsTableRow<UserDto>[] = users.map((row) => ({
    value: row,
    nodes: [
      <b>{row.userName}</b>,
      row.mailAddress,
      operationalUnits.find((operationalUnit) => operationalUnit.unitNumber === row.unitNumber)?.unitName,
      row.userRoles.map((userRole) => (
        <div key={userRole}>{userRoleNames.find((userRoleName) => userRoleName.key === userRole)?.value}</div>
      )),

      <UserTableMenu user={row} />,
    ],
  }));
  return <ImsTable hasMenu={true} headCells={headCells} rows={rows} />;
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const mapStateToProps = (state: IStore) => ({
  users: state.users.users,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(UserTable);
