import { TreeItem } from "@mui/lab";
import countries from "iso3166-2-db/countryList/dispute/UN/de.json";
import { Grid, Link, Paper, Typography, useTheme } from "@mui/material";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";
import { OperationalUnitStatus } from "../../store/models/operationalUnit/OperationalUnitStatus";
import { COLOR_BLACK, COLOR_GRAY_4 } from "../atoms/ImsMaterialTheme";
import OperationalUnitCardMenu from "./OperationalUnitCardMenu";
import { useState } from "react";

interface OperationalUnitCardProps {
  operationalUnit: OperationalUnitDto;
  operationalUnits: OperationalUnitDto[];
}
const styles = {
  root: {
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
      backgroundColor: "transparent",
    },
  },
  content: {},
  label: {},
};

export const OperationalUnitCard = (props: OperationalUnitCardProps) => {
  const theme = useTheme();
  const [page, setPage] = useState(1);
  const { operationalUnit, operationalUnits } = props;
  const childUnits = operationalUnits.filter(
    (ou: OperationalUnitDto) => ou.parentUnitNumber === operationalUnit.unitNumber
  );
  const itemsPerPage = 10;

  return (
    <TreeItem
      sx={{
        root: styles.root,
        content: styles.content,
        label: styles.label,
      }}
      nodeId={operationalUnit.externalUnitNumber}
      label={<OperationalUnitLabel operationalUnit={operationalUnit} />}
    >
      {childUnits.length <= itemsPerPage ? (
        childUnits.map((unit: OperationalUnitDto) => (
          <OperationalUnitCard key={unit.unitNumber} operationalUnit={unit} operationalUnits={operationalUnits} />
        ))
      ) : (
        <>
          {childUnits.slice(0, page * itemsPerPage).map((unit: OperationalUnitDto) => (
            <OperationalUnitCard key={unit.unitNumber} operationalUnit={unit} operationalUnits={operationalUnits} />
          ))}

          {page * itemsPerPage < childUnits.length && (
            <Grid container justifyContent="flex-end">
              <Link
                style={{ cursor: "pointer", marginRight: theme.spacing(3) }}
                onClick={() => {
                  setPage(page + 1);
                }}
              >
                <Typography variant="button">mehr anzeigen</Typography>
              </Link>
            </Grid>
          )}
        </>
      )}
    </TreeItem>
  );
};

interface OperationalUnitLabelProps {
  operationalUnit: OperationalUnitDto;
}

function OperationalUnitLabel({ operationalUnit }: OperationalUnitLabelProps) {
  const theme = useTheme();
  const color = operationalUnit.unitStatus === OperationalUnitStatus.DISABLED ? COLOR_GRAY_4 : COLOR_BLACK;
  return (
    <Paper
      style={{
        padding: theme.spacing(1.5, 3),
        height: theme.spacing(10),
        marginBottom: theme.spacing(1),
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={3}>
          <b
            style={{
              color,
            }}
          >
            {operationalUnit.unitName}
          </b>
        </Grid>
        <Grid item xs={2}>
          <span
            style={{
              color,
            }}
          >
            {operationalUnit.externalUnitNumber}
          </span>
        </Grid>
        <Grid item xs={2}>
          {operationalUnit.contact?.address && (
            <Typography
              variant="subtitle2"
              style={{
                color,
              }}
            >
              {operationalUnit.contact?.address?.streetName} {operationalUnit.contact?.address?.houseNumber}
              <br />
              {operationalUnit.contact?.address?.zipCode} {operationalUnit.contact?.address?.cityName}
              <br />
              {operationalUnit.contact?.address?.country &&
                countries[operationalUnit.contact?.address?.country as keyof typeof countries].name}
            </Typography>
          )}
        </Grid>
        <Grid item xs={4}>
          {operationalUnit.contact && (
            <Typography
              variant="subtitle2"
              style={{
                color,
              }}
            >
              {operationalUnit.contact.firstName} {operationalUnit.contact.lastName}
              <br />
              {operationalUnit.contact.phoneNumber}
              <br />
              {operationalUnit.contact.mailAddress}
            </Typography>
          )}
        </Grid>
        <Grid item xs={1}>
          <OperationalUnitCardMenu operationalUnit={operationalUnit} />
        </Grid>
      </Grid>
    </Paper>
  );
}
