import { useState } from "react";
import { Box, Button, CircularProgress, Container, LinearProgress, Stack, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMountEffect } from "../../atoms/Utils";
import { Api } from "../../../api/Api";
import {
  thunkCreateErrorNotification,
  thunkCreateSuccessNotification,
  thunkCreateWarningNotification,
} from "../../../store/actions/NotificationActions";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import ForwardIcon from "@mui/icons-material/Forward";
import { useNavigate } from "react-router-dom";
import { AppSplashDto } from "../../../store/models/appcontent/AppSplashDto";

const AppContentSplashView = (props: ThunkProps) => {
  const [isImageDownloading, setIsImageDownloading] = useState<boolean>(true);
  const [processing, setProcessing] = useState<boolean>(true);
  const [currentImage, setCurrentImage] = useState<AppSplashDto | null>(null);
  const [uploadImage, setUploadImage] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string>();
  const navigate = useNavigate();
  useMountEffect(() => {
    Api.getSplashImage()
      .then((data) => {
        if (data) {
          setCurrentImage(data);
        } else {
          props.thunkCreateWarningNotification(
            "Warnung!",
            "Sie haben bis jetzt kein Bild für den Startbildschirm in der App hochgeladen"
          );
        }
      })
      .catch((e) => {
        props.thunkCreateErrorNotification("Fehler beim Laden der Startbildschirm", e);
      })
      .finally(() => {
        setProcessing(false);
      });
  });
  const reload = () => {
    navigate(0);
  };
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDeleteImage = () => {
    setProcessing(true);
    Api.deleteSplashImage()
      .then((data) => {
        reload();
        props.thunkCreateSuccessNotification("Startbildschirm erfolgreich gelöscht!");
      })
      .catch((_) => {
        props.thunkCreateErrorNotification("Error", "Fehler beim Löschen des Startbildschirms!");
      })
      .finally(() => {
        setProcessing(false);
      });
  };
  const handleUploadImage = () => {
    if (!uploadImage) return;
    setProcessing(true);
    const formData = new FormData();
    formData.set("file", uploadImage);
    Api.uploadSplashImage(formData)
      .then((_) => {
        reload();
        props.thunkCreateSuccessNotification("Startbildschirm erfolgreich hochgeladen!");
      })
      .catch((e) => {
        props.thunkCreateErrorNotification("Error", "Fehler beim Hochladen des Startbildschirms!");
      })
      .finally(() => {
        setProcessing(false);
      });
  };
  return (
    <Container>
      {processing && <LinearProgress />}
      <Stack direction="row" justifyContent="center" alignItems="center">
        {currentImage && (
          <Box mt={2} position={"relative"} minHeight={"15vh"} mr={2}>
            <Typography variant="h6" gutterBottom textAlign={"center"}>
              {currentImage.imageName}
            </Typography>
            <img
              src={currentImage.imageLink}
              alt="Current Splashscreen"
              onLoad={() => setIsImageDownloading(false)}
              style={{
                maxWidth: "100%",
                maxHeight: "500px",
                width: "auto",
                height: "auto",
                borderRadius: "8px",
              }}
            />
            {isImageDownloading && (
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "10vh",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        )}
        {currentImage && uploadImage && <ForwardIcon color="primary" style={{ fontSize: "5em" }} />}
        {uploadImage && (
          <Box mt={2} position={"relative"} minHeight={"15vh"} ml={2}>
            <Typography variant="h6" gutterBottom textAlign={"center"}>
              {uploadImage.name}
            </Typography>
            <img
              src={previewImage}
              data-testid="preview-image"
              alt="Selected"
              style={{
                maxWidth: "100%",
                maxHeight: "500px",
                width: "auto",
                height: "auto",
                borderRadius: "8px",
              }}
            />
          </Box>
        )}
      </Stack>
      <Box mt={2} display="flex" justifyContent="center" width="100%">
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="secondary"
            disabled={processing || !currentImage}
            startIcon={<DeleteIcon />}
            onClick={handleDeleteImage}
          >
            Löschen
          </Button>
          <label htmlFor="raised-button-file">
            <Button
              variant="contained"
              color="primary"
              disabled={processing}
              component="span"
              startIcon={<CloudUploadIcon />}
            >
              Bild Wählen
            </Button>
          </label>
          <Button
            variant="contained"
            color="primary"
            component="span"
            disabled={processing || !uploadImage}
            startIcon={<CloudUploadIcon />}
            onClick={handleUploadImage}
          >
            Hochladen
          </Button>
        </Stack>
      </Box>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        data-testid="file-upload-input"
        type="file"
        onChange={handleImageChange}
      />
    </Container>
  );
};
const mapStateToProps = (_state: IStore) => ({});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkCreateSuccessNotification,
      thunkCreateWarningNotification,
      thunkCreateErrorNotification,
    },
    dispatch
  );
const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(AppContentSplashView);
