import { Button, Grid, Paper, Tooltip, useTheme } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathIdentificationType } from "../../../api/url";
import {
  thunkCreateIdentificationType,
  thunkUpdateIdentificationType,
} from "../../../store/actions/IdentificationActions";
import { IStore } from "../../../store/IStore";
import { IdentificationTypeDto } from "../../../store/models/identification/IdentificationTypeDto";
import { FormCheckbox } from "../../atoms/FormCheckbox";
import { FormInput } from "../../atoms/FormInput";
import ImsPaperHead from "../../atoms/ImsPaperHead";
import { isBlank, nameof, ValidationErrors } from "../../atoms/Utils";
import { FormSelect } from "../../atoms/FormSelect";
import { Help } from "@mui/icons-material";
import { PassMediumDto } from "../../../store/models/passmedium/PassMediumDto";

interface IdentificationTypeFormProps {
  identificationType: IdentificationTypeDto;
}

const IdentificationTypeForm = (props: IdentificationTypeFormProps & ThunkProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const { identificationType, einGeneratorTemplates, passMediums } = props;

  const saveIdentificationType = async (identificationTypeToSave: IdentificationTypeDto) => {
    if (identificationTypeToSave.identificationTypeNumber) {
      await props.thunkUpdateIdentificationType(identificationTypeToSave);
    } else {
      await props.thunkCreateIdentificationType(identificationTypeToSave);
    }
    setRedirect(true);
  };

  if (redirect) {
    return <Navigate to={UrlPathIdentificationType} />;
  }

  const validateForm = (values: IdentificationTypeDto) => {
    const errors: ValidationErrors<IdentificationTypeDto> = {};
    if (isBlank(values.identificationTypeName)) {
      errors.identificationTypeName = "Bitte geben Sie eine valide Bezeichnung des Pass Templates ein.";
    }
    if (isBlank(values.externalIdentificationTypeNumber)) {
      errors.externalIdentificationTypeNumber = "Bitte geben Sie eine Kennung des Pass Templates ein.";
    }
    return errors;
  };

  return (
    <Form
      onSubmit={saveIdentificationType}
      initialValues={identificationType}
      validate={validateForm}
      render={({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <Paper
            style={{
              padding: theme.spacing(3),
              marginTop: theme.spacing(6),
            }}
          >
            <ImsPaperHead text="Wie lauten die Details des neuen Pass Templates?" />
            <Grid container spacing={4}>
              <Grid item container spacing={2}>
                <Grid item sm={12}>
                  <FormCheckbox name={nameof<IdentificationTypeDto>("defaultIdentification")} label="Standard" />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name={nameof<IdentificationTypeDto>("identificationTypeName")}
                    label="Bezeichnung des Pass Templates"
                  />
                </Grid>

                <Grid item sm={8}></Grid>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name={nameof<IdentificationTypeDto>("externalIdentificationTypeNumber")}
                    label="Kennung des Pass Templates"
                    readOnly={Boolean(values.identificationTypeNumber)}
                  />
                </Grid>
                <Grid item sm={8}></Grid>
                <Grid item sm={4}>
                  <FormSelect
                    name={nameof<IdentificationTypeDto>("templateName")}
                    label={"Pass ID Muster"}
                    options={[{ label: "bitte auswählen...", value: "" }].concat(
                      einGeneratorTemplates.map((template) => ({
                        label: template.displayName ? template.displayName : template.name,
                        value: template.name,
                      }))
                    )}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Tooltip
                    data-testid="passIdMusterTooltip"
                    title={"Wählen Sie das Muster, nach dem Pass IDs Ihrer Kunden generiert wird."}
                    placement="right-start"
                    style={{ marginTop: 16 }}
                  >
                    <Help color="primary" />
                  </Tooltip>
                </Grid>
                <Grid item sm={7}></Grid>
                <Grid item sm={4}>
                  <FormSelect
                    testId="passMediumDropdown"
                    name={nameof<PassMediumDto>("passMediumNumber")}
                    label={"Pass Medium"}
                    options={[{ label: "bitte auswählen...", value: "" }].concat(
                      passMediums.map((passMedium) => ({
                        label: passMedium.motiv + " " + passMedium.type,
                        value: passMedium.passMediumNumber,
                      }))
                    )}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Tooltip
                    data-testid="passMediumTooltip"
                    title={"Wählen Sie das Medium, mit dem dieses Pass Template verbunden wird."}
                    placement="right-start"
                    style={{ marginTop: 16 }}
                  >
                    <Help color="primary" />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Grid container justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setRedirect(true)}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              Abbrechen
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={submitting}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              Speichern
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};

const mapStateToProps = (state: IStore) => ({
  einGeneratorTemplates: state.einGeneratorTemplates.einGeneratorTemplates,
  passMediums: state.passMediums.passMediums,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkUpdateIdentificationType,
      thunkCreateIdentificationType,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(IdentificationTypeForm);
