import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../../store/IStore";
import MenuLayout from "../../app/MenuLayout";
import { AppLinkDto } from "../../../store/models/appcontent/AppLinkDto";
import AppContentLinkDetail from "./AppContentLinkDetail";

const defaultAppLink: AppLinkDto = {
  appLinkNumber: "",
  url: "",
  text: "",
  position: "",
  order: 0,
};

const AppContentLinkDetailFormPage = (props: ThunkProps) => {
  const { appLinks } = props;
  const { id } = useParams<"id">();
  const appLink = appLinks.find((appLink) => appLink.appLinkNumber === id);

  return (
    <MenuLayout headline={id ? "App Content Link bearbeiten" : "Neuer App Content Link"}>
      <AppContentLinkDetail appLink={appLink || defaultAppLink} />
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  appLinks: state.appLinks.links,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;

export default connector(AppContentLinkDetailFormPage);
